<template>
  <div>
    <v-container class="mx-auto">
      <v-row>
        <v-col cols="10" class="px-2">
          <v-text-field
            solo
            hide-details
            label="Mahsulotlar va toifalarni qidirish"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="2" class="px-2">
          <div style="width: 100%; padding: 7px; background-color: #f5f7f9; border-radius: 9px; display: flex; justify-content: center; align-items: center">
            <v-btn icon><v-icon>mdi-tune</v-icon></v-btn>
          </div>
        </v-col>
<!--        <v-col cols="12">-->
<!--          <v-breadcrumbs class="pt-2 pl-3" :items="breadItems"></v-breadcrumbs>-->
<!--        </v-col>-->
<!--        <v-col cols="12">-->
<!--          <v-chip class="ma-2 px-2" color="success" outlined>Yangi</v-chip>-->
<!--          <v-chip class="ma-2 px-2" color="success" outlined>Ko'p sotilgan</v-chip>-->
<!--          <v-chip class="ma-2 px-2" color="success" outlined>Qimmatroq</v-chip>-->
<!--          <v-chip class="ma-2 px-2" color="success" outlined>Arzonroq</v-chip>-->

<!--        </v-col>-->
<!--        <v-col cols="12">-->
<!--          <carousel-->
<!--            :per-page="1"-->
<!--            :loop="true"-->
<!--            :speed="1500"-->
<!--            :autoplayTimeout="4000"-->
<!--            :autoplay="true"-->
<!--            :paginationEnabled="false"-->
<!--            :mouse-drag="true"-->
<!--            :scrollPerPage="true"-->
<!--          >-->
<!--            <slide v-for="(integration, index) in sliderData" :key="index" class="text-center">-->
<!--              <div class="box-style">-->
<!--                <div class="imgBox1">-->
<!--                  <img width="400" :src="require(`../assets/${integration.image}`)" alt="">-->
<!--                </div>-->
<!--              </div>-->
<!--            </slide>-->
<!--          </carousel>-->
<!--        </v-col>-->
      </v-row>
<!--      <v-row>-->
<!--        <v-col cols="12" class="px-2" @click="$router.push('/category')">-->
<!--          <div class="d-flex justify-space-between align-center" style="background-color: white; border-radius: 9px; padding: 7px 10px;">-->
<!--            <div>-->
<!--              <p style="font-size: 16px; color: #1C293E; font-weight: bold; margin-bottom: 0;">Категории</p>-->
<!--              <span style="color:#a4a9b2; text-transform: uppercase; font-size: 12px; line-height: 12px; font-weight: 550;">Все товары</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <v-icon large>mdi-chevron-right</v-icon>-->
<!--            </div>-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-row class="pb-10 px-3">
        <v-col cols="12" class="py-2">
          <p style="font-size: 19px; color: #1C293E; font-weight: bold; margin-bottom: 0;">Product List</p>
        </v-col>
        <v-col cols="6" v-for="(integration, index) in listPro" :key="index">
          <div class="singleBox" @click="jumpDetailInfo(integration.id, integration.type)">
            <div class="productBox">
              <div class="imgBox2">
                <img width="350" :src="require(`../assets/${integration.image}`)" alt="">
              </div>
            </div>
            <div>
              <p class="mb-0 mt-2 ml-1 text-start" style="font-weight: bold; color: #B5B5B6">{{ integration.name }}</p>
              <p class="mb-1 ml-1 text-start" style="font-weight: bold; font-size: 17px; color: black">{{integration.price}} $</p>
            </div>
            <div class="d-flex justify-space-between align-center px-1">
              <div class="text-start">
                <p style="font-weight: bold; color: #B5B5B6; margin-bottom: 0;">Color</p>
                <div class="d-flex align-center">
                  <div style="width: 12px; height:12px; background-color: #D9D9D9; border: 1px solid #1872F6; border-radius: 50%; margin: 0 2px;"></div>
                  <div style="width: 12px; height:12px; background-color: #F6C001; border-radius: 50%; margin: 0 2px;"></div>
                  <div style="width: 12px; height:12px; background-color: #313131; border-radius: 50%; margin: 0 2px;"></div>
                </div>
              </div>
              <div>
                <img src="../assets/shop.png" alt="">
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "Home",
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      breadItems: [
        {
          text: 'Home',
          disabled: false,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Link 1',
          disabled: false,
          href: 'breadcrumbs_link_1',
        },
        {
          text: 'Link 2',
          disabled: true,
          href: 'breadcrumbs_link_2',
        },
      ],
      currentItem: 'tab-Web',
      items: ["Ommabop", "Yaqinda qo'shilgan"],
      more: [
        'News', 'Maps', 'Books', 'Flights', 'Apps',
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      value: "bexruz",
      sliderData:[
        {
          id:1,
          image:"banner.jpg"
        },
        {
          id:2,
          image:"banner1.jpg"
        },
        {
          id:3,
          image:"banner2.jpg"
        },
        {
          id:4,
          image:"banner3.jpg"
        },
        {
          id:5,
          image:"banner4.jpg"
        },
      ],
      listPro: [
        {
          id:1,
          name: 'Skater Dress',
          price:166,
          image:"image4.png",
          type: 'dress'
        },
        {
          id:2,
          name: 'Black T-Shirt',
          price:87,
          image:"image4.png",
          type: 'dress'
        },
        {
          id: 3,
          name: 'Blue Dress For Woman',
          price:98,
          image:"image4.png",
          type: 'dress'
        },
        {
          id:4,
          name: 'V-Neck Dress',
          price:94,
          image:"image4.png",
          type: 'dress'
        },
        {
          id:5,
          name: 'Maxi Dress',
          price:159,
          image:"image4.png",
          type: 'dress'
        },
        {
          id:6,
          name: 'Fit-Flare Dress',
          price:133,
          image:"image4.png",
          type: 'dress'
        },
        {
          id:7,
          name: 'Midi Dress',
          price:70,
          image:"image4.png",
          type: 'dress'
        },
        {
          id:8,
          name: 'Tulip Dress',
          price:130,
          image:"image4.png",
          type: 'dress'
        }
      ]
    };
  },
  methods: {
    jumpDetailInfo(id, vm){
      this.$router.replace({ name: "ProductData", query: {type: vm, id:id} })
    },
    addItem (item) {
      const removed = this.items.splice(0, 1)
      this.items.push(
        ...this.more.splice(this.more.indexOf(item), 1),
      )
      this.more.push(...removed)
      this.$nextTick(() => { this.currentItem = 'tab-' + item })
    },
  },
};
</script>

<style lang="scss" scoped>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
::v-deep .v-slide-group__content{
  display: flex;
  justify-content: center;
}

.box-style {
  width: 93%;
  height: 235px;
  display: block;
  margin: 0 auto;
  padding: 10px 0 0 0;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.4s ease-out;
  position: relative;
  overflow: visible;

}

.labelPositionLeft{
  position: absolute;
  left: 10px;
  top: 10px;
  width: 70px;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  background-color: orange;
  z-index: 10;
}

.labelPositionRight{
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: white;
  border:1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.imgBox1{
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 9px;
  align-items: center;
  z-index: 1;
}

.labelPositionRight1{
  transform: translate(100%, 110%);
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: white;
  border:1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: 0.3s ease-out;
}
.labelPositionRight2{
  transform: translate(100%, 220%);
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
  height: 30px;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  background-color: white;
  border:1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transition: 0.3s ease-out;

}

.singleBox{
  border-radius: 9px;
  background-color: white;
  margin: 6px 5px;
  padding: 10px 7px;
  box-shadow:0px 2px 5px 2px rgba(174, 219, 241, 0.5);
}

.productBox{
  width: 100%;
  background-color: #efefef;
  position: relative;
  border-radius: 9px;
  padding: 10px 7px;
}
.imgBox2{
  position: relative;
  width: 100%;
  height: 170px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot{
  box-shadow: 0px 3px 1px -2px rgba(174, 219, 241, 0.5), 0px 2px 2px 0px rgba(174, 219, 241, 0.3), 0px 1px 5px 0px rgba(174, 219, 241, 0.2);
}
</style>
