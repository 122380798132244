<template>
  <div style="background-color: #f5f7f9">
    <v-container class="mx-auto my-5">
      <v-row>
        <v-col class="text-center" cols="12" v-if="!tags.length">
          <v-progress-circular
            :size="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
        <v-col
          v-else
          class="my-2"
          cols="12"
          v-for="tag in tags"
          :key="tag.id"
        >
          <router-link class="picPosition px-2" :to="`/tag/${tag.text}`">
            <div class="d-flex justify-space-between align-center" style="width:100%; background-color: white; border-radius: 9px; padding: 7px 10px;">
              <div class="d-flex justify-start align-center">
                <div class="mr-2"><v-icon :color="tag.color">mdi-{{ tag.icon }}</v-icon></div>
                <div><p style="font-size: 16px; color: #1C293E; font-weight: bold; margin-bottom: 0;">{{ tag.text }}</p></div>
              </div>
              <div>
                <v-icon large>mdi-chevron-right</v-icon>
              </div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tags: [
        {
          id: 1,
          text: "Maktab bozori",
          icon: "school",
          color: "blue",
        },
        {
          id: 2,
          text: "Kanselyarya",
          icon: "notebook",
          color: "yellow",
        },
        {
          id: 3,
          text: "Elektronika",
          icon: "headphones",
          color: "grey",
        },
        {
          id: 4,
          text: "Maishiy texnika",
          icon: "blender",
          color: "purple",
        },
        {
          id: 5,
          text: "Kiyim",
          icon: "tshirt-crew",
          color: "red",
        },
        {
          id: 6,
          text: "Poyabzallar",
          icon: "shoe-formal",
          color: "black",
        },
        {
          id: 7,
          text: "Aksessuarlar",
          icon: "teddy-bear",
          color: "green",
        },
        {
          id: 8,
          text: "Kasmetika",
          icon: "hair-dryer",
          color: "blue",
        },
        {
          id: 9,
          text: "Salomatlik",
          icon: "heart",
          color: "red",
        },
        {
          id: 10,
          text: "Uy ro'zg'or buyumlari",
          icon: "home",
          color: "teal",
        },
        {
          id: 11,
          text: "Qurulish va ta'mirlash",
          icon: "office-building-cog",
          color: "red",
        },
        {
          id: 12,
          text: "Avtotovarlar",
          icon: "car",
          color: "black",
        },
        {
          id: 13,
          text: "Kitoblar",
          icon: "bookshelf",
          color: "red",
        },
        {
          id: 14,
          text: "Sport",
          icon: "basketball",
          color: "orange",
        },   {
          id: 12,
          text: "Oziq-ovqat",
          icon: "food-apple",
          color: "green",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.picPosition {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.colorPosition {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  font-size: 22px;
  background-color: rgba(98, 102, 67, 0.61);
}
</style>
