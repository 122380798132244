import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Category from "@/views/Category";
import Error from "@/components/Error";
import Direct from "@/views/Direct";
import Product from "@/views/Product";
import Collect from "@/views/Collect";
import Cabinet from "@/views/Cabinet";
import Ommabop from "@/views/Ommabop";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
  },
  {
    path: "/product",
    name: "ProductData",
    component: Product,
  },
  {
    path: "/collect",
    name: "CollectProduct",
    component: Collect,
  },
  {
    path: "/ommabop",
    name: "Ommabop",
    component: Ommabop,
  },
  {
    path: "/cabinet",
    name: "Cabinet",
    component: Cabinet,
  },
  // {
  //   path: "/artist",
  //   name: "artist",
  //   component: Artist,
  // },
  // {
  //   path: "/artist/:artist",
  //   name: "artist-info",
  //   component: Artist,
  // },
  {
    path: "/artist/:artist/:song",
    name: "artist-song-info",
    component: Direct,
  },
  {
    path: "*",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
