<template>
  <div>
    <v-container class="mx-auto py-10">
      <v-row>
        <v-col cols="12" class="px-3 mt-4">
          <p class="text-h4">Collect Page</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Collect",

  data: () => ({
    search: "",
    loading: false,
  }),

};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
}

</style>
