<template>
  <div>
    <nav>
      <v-navigation-drawer class="main__style" v-model="drawer" dark app color="white">
        <div class="d-flex justify-start align-center text-center pt-3 pb-2 pl-2">
          <img src="../assets/logotip.png" class="mr-3" alt="">
          <p style="font-size: 19px; color: #1C293E; font-weight: bold; margin-bottom: 0;">Diamond Shop</p>
        </div>
        <v-list dense nav class="px-2 pt-2">
          <v-list-item-group :value="selectedItem" color="gray" style="overflow-y: auto;">
            <v-list-item @click="$router.push('/')" class="d-flex align-center" style="border-bottom: 1px solid #8080801f" :style="$route.name.includes('home') ? 'background-color:#9b9b9b26' : 'background-color:#ffffff'">
              <v-list-item-icon>
                <v-icon v-if="!$route.name.includes('home')" color="#1C293E">mdi-home-outline</v-icon>
                <v-icon v-else color="#1C293E">mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pb-0">
                <p class="mb-0" style="font-size: 14px; font-weight: 550; color:#1C293E;">Home</p>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push('/category')" class="d-flex align-center" style="border-bottom: 1px solid #8080801f" :style="$route.name.includes('Category') ? 'background-color:#9b9b9b26' : 'background-color:#ffffff'">
              <v-list-item-icon>
                <v-icon v-if="!$route.name.includes('Category')" color="#1C293E">mdi-shape-plus-outline</v-icon>
                <v-icon v-else color="#1C293E">mdi-shape-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pb-0">
                <p class="mb-0" style="font-size: 14px; font-weight: 550; color:#1C293E">Katalog</p>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push('/collect')" class="d-flex align-center" style="border-bottom: 1px solid #8080801f" :style="$route.name.includes('CollectProduct') ? 'background-color:#9b9b9b26' : 'background-color:#ffffff'">
                <v-list-item-icon>
                  <v-icon v-if="!$route.name.includes('CollectProduct')" color="#1C293E">mdi-basket-outline</v-icon>
                  <v-icon v-else color="#1C293E">mdi-basket</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pb-0">
                  <p class="mb-0" style="font-size: 14px; font-weight: 550; color:#1C293E">Savat</p>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push('/ommabop')" class="d-flex align-center" style="border-bottom: 1px solid #8080801f" :style="$route.name.includes('Ommabop') ? 'background-color:#9b9b9b26' : 'background-color:#ffffff'">
                <v-list-item-icon>
                  <v-icon v-if="!$route.name.includes('Ommabop')" color="#1C293E">mdi-star-outline</v-icon>
                  <v-icon v-else color="#1C293E">mdi-star</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pb-0">
                  <p class="mb-0" style="font-size: 14px; font-weight: 550; color:#1C293E">Mashhur</p>
                </v-list-item-content>
            </v-list-item>
            <v-list-item @click="$router.push('/cabinet')" class="d-flex align-center" style="border-bottom: 1px solid #8080801f" :style="$route.name.includes('Cabinet') ? 'background-color:#9b9b9b26' : 'background-color:#ffffff'">
                <v-list-item-icon>
                  <v-icon v-if="!$route.name.includes('Cabinet')" color="#1C293E">mdi-account-outline</v-icon>
                  <v-icon v-else color="#1C293E">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="pb-0">
                  <p class="mb-0" style="font-size: 14px; font-weight: 550; color:#1C293E">Seller</p>
                </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar color="white" dark app>
        <v-app-bar-nav-icon color="#1C293E" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <div class="d-flex align-center" @click="$router.push('/')">
          <img width="45" src="../assets/logotip.png" class="pr-2" alt="">
          <p style="font-size: 17px; color: #1C293E; font-weight: bold; margin-bottom: 0;">Diamond Shop</p>
        </div>
        <v-spacer></v-spacer>
        <div>
          <div class="d-flex justify-start align-center">
            <v-btn icon>
              <v-icon color="black">mdi-basket-outline</v-icon>
            </v-btn>

            <v-btn icon>
              <v-badge
                color="red"
                content="1"
              >
              <v-icon color="red">mdi-heart-outline</v-icon>
              </v-badge>
            </v-btn>

            <v-btn icon>
              <v-icon color="black">mdi-account-outline</v-icon>
            </v-btn>

          </div>
        </div>
      </v-app-bar>
    </nav>
  </div>
</template>

<script>

export default {
  name: "AdminNavbar",
  data: () => ({
    drawer: false,
    group: null,
    selectedItem:null,
    items: [
      { flag: "lat", state: "O'zbek", value: "uz", appear: true },
      { flag: "russia", state: "Русский", value: "ru", appear: true },
      { flag: "united-states", state: "English", value: "en", appear: true },
    ],
    links: [
      {
        title: "Dashboard",
        icon: "slduhdil",
        to: "/",
      },
      {
        title: "News",
        icon: "uvlzcswc",
        to: "/news",
      },
      {
        title: "News Images",
        icon: "htujxsuy",
        to: "/news-images",
      },
      {
        title: "Menu",
        icon: "xhcrhqyw",
        to: "/menu",
      },
      {
        title: "Carousel",
        icon: "msetysan",
        to: "/carousel",
      },
      {
        title: "Rahbariyat",
        icon: "nkrqhzkh",
        to: "/rahbariyat",
      },
      {
        title: "New Page",
        icon: "oezixobx",
        to: "/new-page",
      },
      {
        title: "New Page Images",
        icon: "htujxsuy",
        to: "/new-page-images",
      },
      {
        title: "New Employee",
        icon: "msetysan",
        to: "/new-employee",
      },
      {
        title: "Fotogalareya",
        icon: "htujxsuy",
        to: "/fotos",
      }
    ]
  }),


  methods: {
    routerLink(to) {
      this.$router.push(to);
    },
    routerVirtual(to) {
      this.$router.push(to);
    },
    logout() {
      localStorage.removeItem("user");
      this.$router.push("/login");
    }

  }
}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.logout__style {
  width: 100%;
  padding: 0 16px;
  border-top: 1px solid grey;
  border-radius: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}
::v-deep .v-list-item__icon{
  margin-right: 15px !important;
}
</style>
