<template>
  <div>
    <v-container class="mx-auto pb-10">
      <v-row>
        <v-col cols="12">
          <v-carousel
            :continuous="false"
            :cycle="true"
            :show-arrows="false"
            hide-delimiter-background
            delimiter-icon="mdi-circle-medium"
            height="300"
            style="padding: 10px;"
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-sheet
                :color="colors[i]"
                height="100%"
                tile
              >
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center"
                >
                  <div>
                    <img width="350" src="../assets/image5.png" alt="">
                  </div>
                </v-row>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" class="pt-3 px-3">
          <p style="font-size: 16px; color: #1C293E; font-weight: bold; margin-bottom: 5px; padding-left: 4px">Product name</p>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center pt-2 px-1 pb-1">
            <p style="font-size: 16px; font-weight: bold; color: #B5B5B6; margin-bottom: 0;">Color</p>
            <div class="d-flex align-center">
              <div style="width: 20px; height:20px; background-color: #D9D9D9; border: 1px solid #1872F6; border-radius: 50%; margin: 0 2px;"></div>
              <div style="width: 20px; height:20px; background-color: #F6C001; border-radius: 50%; margin: 0 2px;"></div>
              <div style="width: 20px; height:20px; background-color: #313131; border-radius: 50%; margin: 0 2px;"></div>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex justify-space-between align-center pt-2 px-1 pb-1">
            <p style="font-size: 16px; font-weight: bold; color: #B5B5B6; margin-bottom: 0;">Size</p>
            <div class="d-flex align-center">
              <div class="sizeStyle">15</div>
              <div class="sizeStyle">16</div>
              <div class="sizeStyle" style="border: 1px solid #1872F6;">17</div>
              <div class="sizeStyle">18</div>
              <div class="sizeStyle">19</div>
            </div>
          </div>
          <v-divider></v-divider>
          <p class="pt-2 px-1" style="font-size: 15px; color: #1C293E; font-weight: bold; margin-bottom: 5px; padding-left: 4px">Description</p>
          <p class="px-1" style="font-size: 14px; color: #1C293E; margin-bottom: 5px; padding-left: 4px">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vitae feugiat mauris, nec cursus nunc.
          </p>
          <ul class="pb-4">
            <li style="font-size: 14px; color: #1C293E;">Vestibulum ultricies nunc at</li>
            <li style="font-size: 14px; color: #1C293E;">elementum euismod. Pellentesque</li>
            <li style="font-size: 14px; color: #1C293E;">feugiat enim at tempor ornare.</li>
            <li style="font-size: 14px; color: #1C293E;">Nam at purus sed eros maximus</li>
            <li style="font-size: 14px; color: #1C293E;">posuere ut quis eros.</li>
          </ul>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" class="px-3">
          <v-row>
            <v-col cols="4" v-for="(integration, index) in listPro" :key="index">
              <div class="singleBox" @click="jumpDetailInfo(integration.id, integration.type)">
                <div class="productBox">
                  <div class="imgBox2">
                    <img width="110" :src="require(`../assets/${integration.image}`)" alt="">
                  </div>
                </div>
                <div>
                  <p class="mb-1 mt-2 ml-1 text-start" style="font-weight: bold; color: #B5B5B6">{{ integration.name }}</p>
                  <p class="mb-1 ml-1 text-start" style="font-weight: bold; font-size: 15px; color: black">{{integration.price}} $</p>
                </div>
              </div>
              <v-divider class="mt-3"></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="px-3 pt-3">
          <div class="d-flex justify-space-between align-center px-1">
            <div class="text-start"><p style="font-size:18px; font-weight: bold; margin-bottom: 0;">999$</p></div>
            <div><img src="../assets/shop.png" alt=""></div>
          </div>
        </v-col>

        <v-col cols="12" class="px-3 mt-4">
          <v-btn
            style="width: 100%; color: white; font-weight: bold; text-transform: capitalize;"
            :loading="loading"
            :disabled="loading"
            color="blue"
            @click="loader = 'loading'"
          >
            <img class="pr-1" src="../assets/shop1.png" alt="">
            Savatga
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "HelloWorld",

  data: () => ({
    search: "",
    loading: false,
    listPro: [
      {
        id:1,
        name: 'Product name',
        price:166,
        image:"image4.png",
        type: 'dress'
      },
      {
        id:2,
        name: 'Product name',
        price:87,
        image:"image4.png",
        type: 'dress'
      },
      {
        id: 3,
        name: 'Product name',
        price:98,
        image:"image4.png",
        type: 'dress'
      }
    ],
    slides: [
      'First',
      'Second',
      'Third',
    ],
    colors: [
      '#efefef',
      '#efefef',
      '#efefef'
    ],
    bases: [
      { id: 1, title: "Audio Streaming Platforms" },
      { id: 2, title: "Make Your Own Radio Station" },
      { id: 3, title: "Download Music Albums" },
      { id: 4, title: "Top 10 Songs of 2021" },
    ],
  }),
  created() {
    console.log(this.$route.params.artist);
    this.getArtistInfoTopAlbums(this.$route.params.artist);
    this.getArtistTopAlbums(this.$route.params.artist);
  },
  computed: {
    ...mapGetters("artistInfo", ["artistInfo"]),
    ...mapGetters("artist", ["artist"]),

    findMusic: function () {
      return this.artist.filter((list) => {
        return list.name.includes(
          this.search.replace(/./, (c) => c.toUpperCase())
        );
      });
    },
  },
  methods: {
    ...mapActions("artistInfo", ["getArtistInfoTopAlbums"]),
    ...mapActions("artist", ["getArtistTopAlbums"]),
  },
};
</script>
<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
}
.v-progress-circular {
  margin: 1rem;
}
.picPosition {
  position: relative;
  margin: 0 auto;
}
.imgPosition {
  position: relative;
}
.infoPosition {
  position: absolute;
  top: 50px;
  left: 35px;
}
.playPosition {
  position: absolute;
  top: 18px;
  left: 18px;
}
.linkPosition {
  position: absolute;
  bottom: 25px;
  left: 18px;
  order: 100;
}
.similarPic {
  width: 100%;
  max-width: 88px;
  height: auto;
  border-radius: 100%;
}
::v-deep .v-btn--icon.v-size--small {
  width: 16px;
  height: 16px;
}
.sizeStyle{
  width: 33px;
  height:33px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7F7F7;
  border-radius: 9px;
  font-size: 16px;
  font-weight: bold;
  margin: 0 2px;
}
.singleBox{
  border-radius: 9px;
  background-color: white;
  margin: 7px 3px;
  padding: 5px;
  border: 1px solid #EFEFEF;
}
.productBox{
  width: 100%;
  background-color: #efefef;
  position: relative;
  border-radius: 9px;
  padding: 5px;
}
.imgBox2{
  position: relative;
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
</style>
